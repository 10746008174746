<script setup lang="ts">
type Size = 'medium' | 'large';

const props = withDefaults(
  defineProps<{
    label: string;
    size?: Size;
    avoidLabel?: boolean;
  }>(),
  {
    size: 'medium',
  }
);

const is = computed(() => {
  return props.avoidLabel ? 'div' : 'label';
});
</script>

<template>
  <component :is="is" :class="$style.block">
    <span :class="[$style.block__label, $style[`block__label--${size}`]]">{{
      label
    }}</span>
    <div :class="$style.block__input">
      <slot />
    </div>
  </component>
</template>

<style lang="scss" module>
.block {
  display: flex;
  flex-direction: column;

  &__label {
    font-weight: bold;

    &--medium {
      font-size: 14px;
    }

    &--large {
      font-size: 16px;
    }
  }

  &__input {
    &:nth-child(n + 2) {
      margin-top: 8px;
    }
  }
}
</style>
